import React, { useState } from 'react'
import Phoneimg from '../Assets/ContactDesc/Path 329.png'
import EmailLogo from '../Assets/ContactDesc/Group 317.png'
import linkIInLogo from '../Assets/Linked In Icon.png'
import {IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'



const Footer = () => {
    const [productsShow,setProductsShow] = useState(false)
  return (
    <footer className='w-full h-full flex justify-around items-start pt-10 md:flex-col'>
        <div className="links flex justify-center items-start md:block ">
            <div className="leftLinks flex flex-col gap-8 md:ml-6 md:mb-6 ">
                <h1 onClick ={() => window.scrollTo(0, 0)}><Link to='/' title='Home'>Home</Link></h1>
                <h1 onClick ={() => window.scrollTo(0, 0)}><Link to={'./vendors'} title='Vendors'>Vendors</Link></h1>
                <h1 onClick ={() => window.scrollTo(0, 0)}><Link to='/partners' title='Partners'>Partners</Link></h1>
            </div>
            <div className="rightLinks flex flex-col gap-8 ml-14 md:ml-6">
                <h1 className='relative' onClick={()=>{setProductsShow(!productsShow)}}> <div className='flex justify-center items-center'>Products  <IoIosArrowForward/></div>
                    <ul className={`absolute top-0 left-24 w-[200px]  flex flex-col gap-1 ${!productsShow ?  '' : 'hidden'} md:-top-12 md:block}`}>
                        <li className='text-md text-[#939393] text-[13px] hover:shadow' onClick ={() => window.scrollTo(0, 0)}><Link to="/ITInfrustructure" title='IT Infrastructure'>IT Infrastructure</Link></li>
                        <li className='text-md text-[#939393] text-[13px] hover:shadow' onClick ={() => window.scrollTo(0, 0)}><Link to='/NetworkInfrastructure' title='Network Infrastructure'>Network Infrastructure</Link></li>
                        <li className='text-md text-[#939393] text-[13px] hover:shadow' onClick ={() => window.scrollTo(0, 0)}><Link to='/CyberSecurity' title='Cyber Security'>Cyber Security</Link></li>
                        <li className='text-md text-[#939393] text-[13px] hover:shadow' onClick ={() => window.scrollTo(0, 0)}><Link to='/ManagedSecurityServices' title='Managed Security Service'>Managed Security Service</Link></li>
                        <li className='text-md text-[#939393] text-[13px] hover:shadow' onClick ={() => window.scrollTo(0, 0)}><Link to='/ManagedServices' title='Managed Services'>Managed Services</Link></li>
                        <li className='text-md text-[#939393] text-[13px] hover:shadow' onClick ={() => window.scrollTo(0, 0)}><Link to='/CloudandSecurityServices' title='Cloud Systems and Security'>Cloud Systems and Security</Link></li>
                    </ul>
                </h1>
                <h1 onClick ={() => window.scrollTo(0, 0)}><Link to='/aboutus' title='About us'> About us</Link></h1>
                <h1 onClick ={() => window.scrollTo(0, 0)}><Link to='ContactPage' title='Contact'>Contact</Link></h1>
            </div>
        </div>
        <ul className="Contactinfo flex flex-col gap-4 mb-12 justify-start items-start md:mt-10 md:m-auto md:gap-3 md:mb-6">
                        <li className='pl-28 md:pl-16 '><a href="https://www.linkedin.com/company/gtgroupcloud/" title='LinkedIn'> <img src={linkIInLogo} alt="Linkedin Logo"  className=' w-[47px] h-[47px]'/></a></li>
                        <li className='flex justify-center items-center'>
                            <img src={Phoneimg} alt="Phone image" className='mr-4'/>
                            <h2 className='pl-3'><a href="tel:+37254623682" title='Telephone number'> +37254623682</a></h2>
                        </li>
                        <li className='flex items-center'>
                            <img src={EmailLogo} alt="Email image" className='mr-4'/>
                            <h2><a href="mailto:Info@gtcloud.tech" title='Email'> Info@gtcloud.tech</a></h2>
                        </li>
                    </ul>



    </footer>
  )
}

export default Footer