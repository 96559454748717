import React from 'react'

const About = () => {
  return (
    <div className='w-full h-full mt-6 py-10 pl-[140px] sm:pl-0'>
        <h1 className='text-center text-[30px] font-medium '>GT cloud</h1> 
        <h2 className='text-center max-w-[80%] m-auto text-[#939393] '>Cloud services and security integrators GT Cloud is a full-service consulting company IT based in Estonia. We provide specialized, highly customized technology solutions for small, medium and large enterprises. Using powerful, state-of-the-art development tools, our engineers will build your system from the ground up.
        </h2>   
    </div>
  )
}

export default About