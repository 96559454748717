import React, { useContext, useEffect, useState } from 'react'
import ContactPageImge from '../Assets/Pagescreens/ContactPage.png'
import './ContactPage.css'
import { Loadingcontext } from '../Context/LoadingProvider'
import LoadingPage from '../Context/LoadingPage'
import { Helmet } from 'react-helmet-async'
import emailjs from '@emailjs/browser'
import { TiInputChecked } from 'react-icons/ti';
import { AiFillCloseCircle } from 'react-icons/ai';



const ContactPage = () => {
    const {Loading,setLoading} = useContext(Loadingcontext)
    const [inputValue, setInputvalue] = useState({
        name: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        ITInfrusctucture : false,
        NetworkInfrustructure : false,
        CyberSecurity : false,
        ManagedSecurityService : false,
        ManagedServices : false,
        CloudSystemsandSecurity : false,
        sucess : false,
        Erroe:false,
    })



    useEffect(()=>{
          setLoading(true)
      setTimeout(()=>{
        setLoading(false)
      },1700)
  
    },[])


 
    const Submit = (e) => { 
        e.preventDefault()

            emailjs.send('service_d92hsil','template_322j6qj',inputValue, 'qDWokgwM8ma8lSh_Y' )
            .then(setInputvalue({
                name: '',
                lastname: '',
                email: '',
                phoneNumber: '',
                ITInfrusctucture : false,
                NetworkInfrustructure : false,
                CyberSecurity : false,
                ManagedSecurityService : false,
                ManagedServices : false,
                CloudSystemsandSecurity : false,
                sucess : true,
                Erroe:false,
            })).catch(
                setInputvalue({
                    name: '',
                    lastname: '',
                    email: '',
                    phoneNumber: '',
                    ITInfrusctucture : false,
                    NetworkInfrustructure : false,
                    CyberSecurity : false,
                    ManagedSecurityService : false,
                    ManagedServices : false,
                    CloudSystemsandSecurity : false,
                    sucess : false,
                    Erroe:false,
                })
            );
            setTimeout(()=>{
                        setInputvalue(prev => ({...prev,sucess:false}))
            },1000)
  
        

           

    }


  return (
    <>
    <Helmet>
    <title>Gt Cloud</title>
    <meta name="description" content="Managed Security Services" />
    <link rel="canonical" href="/ContactPage" />
    </Helmet>



    <div>
        {Loading === true && <LoadingPage/> }
        {inputValue.sucess && <div className='sucess h-screen w-screen text-[green] bg-[white] absolute top-0 z-[999999] rounded-3xl  flex justify-center items-center m-auto'>
                <h1 className='flex justify-center items-center gap-2'>Email has been sent! <TiInputChecked/></h1>

        </div>}
    {inputValue.sucess && <div className='sucess h-[150vh] w-screen text-[red] bg-[white] absolute top-0 z-[999999] rounded-3xl  flex justify-center items-center m-auto'>
                <h1 className='flex justify-center items-center gap-2'>Email was not sent! Try again later <AiFillCloseCircle/></h1>

        </div>}
            <div className="hero w-full h-full flex justify-center items-center pl-[140px] sm:pl-0">
    <div className=' w-full h-full rounded-[40px] flex justify-center items-center pt-16 gap-12 ' style={{background:'linear-gradient(to right, #EEEBEB, white)'}} >
            <div className="mt-[40px] object-contain ">
                <h1 className='font-extrabold text-[60px] xl:text-[35px] pl-2 md:text-[25px] sm:text-[18px] '>Contact Us</h1>
            </div>
            <div className="div image pt-6 mb-10 ">
          <img src={ContactPageImge} alt="ContactPageImge" width={450}/>
        </div>
        </div>
    </div>

    <div className='flex max-w-[80%] justify-center gap-9 items-center m-auto mt-10 flex-wrap'>

        <div className="lInputs w-[50%] flex flex-col flex-1 gap-9">
        <input type="text" placeholder="Name (company)" className='input p-2' autoComplete="false" value={inputValue.name} onChange={(e)=>{setInputvalue(prev => ({...prev,name:e.target.value }))}} required/>
            <input type="text" placeholder='Surname ' className='input p-2' autoComplete='false' value={inputValue.lastname} onChange={(e)=>{setInputvalue(prev => ({...prev,lastname:e.target.value }))}}/>
        </div>
        <div className="rightInputs w-[50%] flex flex-col flex-1 gap-9">
        <input type="text" placeholder='E-mail'className='input p-2' autoComplete='false' value={inputValue.email} onChange={(e)=>{setInputvalue(prev => ({...prev,email:e.target.value }))}}/>
        <input type="text" placeholder='Phone number' className='input p-2'  autoComplete='false' value={inputValue.phoneNumber} onChange={(e)=>{setInputvalue(prev => ({...prev,phoneNumber:e.target.value }))}}/>
        </div>
    </div>
        <div className="checkListContainer">
            <h1 className=' text-[17px] pl-20 py-10 m-w-[80%] m-auto'>Which product do you like to receive information about?</h1>
                <div className="checkListContainer max-w-[80%] m-auto flex gap-9 flex-wrap justify-start items-center">
                    <div className="left flex flex-col gap-6 m-auto sm:min-w-[266px]">
                        <div className='item flex gap-4 w-full'>
                            <input type="checkbox" className='checkbox' name="It" placeholder='It Infrusctucture' checked={inputValue.ITInfrusctucture}  onChange={(e) => setInputvalue(prev => ({...prev,ITInfrusctucture:e.target.checked}))}/>
                            <h4 className='font-light text-[17px]'>IT Infrusctucture</h4>
                        </div>
                            <div className='item flex gap-4'>
                        <input type="checkbox" className='checkbox' name="It" placeholder='Network Infrustructure'  checked={inputValue.NetworkInfrustructure} onChange={(e) => setInputvalue(prev => ({...prev,NetworkInfrustructure:e.target.checked}))}/>
                            <h4 className='font-light text-[17px]'>Network Infrustructure</h4>
                        </div>
                        <div className='item flex gap-4'>
                            <input type="checkbox" className='checkbox' name="It" placeholder='Cyber Security' checked={inputValue.CyberSecurity}  onChange={(e) => setInputvalue(prev => ({...prev,CyberSecurity:e.target.checked}))}/>
                            <h4 className='font-light text-[17px]'>Cyber Security</h4>
                        </div>
                    </div>
                    <div className="right flex flex-col gap-6 m-auto ">
                            <div className='item flex gap-4'>
                                <input type="checkbox" className='checkbox' name="It" placeholder='Managed Security Service'  checked={inputValue.ManagedSecurityService} onChange={(e) => setInputvalue(prev => ({...prev,ManagedSecurityService:e.target.checked}))}/>
                                <h4 className='font-light text-[17px]'>Managed Security Service</h4>
                            </div>
                                <div className='item flex gap-4'>
                            <input type="checkbox" className='checkbox' name="It" placeholder='Managed Services'  checked={inputValue.ManagedServices} onChange={(e) => setInputvalue(prev => ({...prev,ManagedServices:e.target.checked}))}/>
                                <h4 className='font-light text-[17px]'>Managed Services</h4>
                            </div>
                            <div className='item flex gap-4'>
                                <input type="checkbox" className='checkbox' name="It" placeholder='Cloud Systems and Security'  checked={inputValue.CloudSystemsandSecurity} onChange={(e) => setInputvalue(prev => ({...prev,CloudSystemsandSecurity:e.target.checked}))}/>
                                <h4 className='font-light text-[17px]'>Cloud Systems and Security</h4>
                            </div>
                    </div>

                </div>
                    <div className="button"> 
                                        <button 
                                        className='w-[256px] h-[68px] p-4 text-[24px] rounded-[43px] mt-4 text-white mb-12 md:w-[148px] md:h-[39px] md:text-[15px] m-auto flex items-center justify-center' 
                                        style={{background:'linear-gradient(to right, #00F5FB , #DC04CB)', color:'white'}} 
                                        onClick={Submit}>
                                            Submit
                                        </button>
                    </div>
        </div>
    </div>
    </>
  )
}

export default ContactPage